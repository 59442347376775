<template>
    <div v-visibility-change="visibilityChange">
        <!-- Login view -->
        <login
            v-if="!loggedIn && !passwordReset"
            ref="login"
            :external-message="loginMessage"
            @resetPassword="resetPassword"
            @closeLoginForm="closeLoginForm"
        />

        <!-- Reset password view -->
        <reset-password-request
            v-if="!loggedIn && passwordReset"
            @resetPasswordDone="resetPasswordDone"
            @resetPasswordClose="resetPasswordClose"
        />

        <!-- Top Bar -->
        <b-navbar
            variant="dark"
            type="dark"
            class="md static-top"
        >
            <b-navbar-brand v-if="loggedIn">
                <button
                    class="top-menu-btn"
                    style="margin-top: .2em"
                    @click.stop="toggleSidebar"
                >
                    <font-awesome-icon icon="bars"/>
                </button>
                <img src="../assets/routa_light.png" style="height: .9em; width: auto; margin-right: .7em; margin-bottom: .4em;" alt="logo"/>
            </b-navbar-brand>

            <b-nav class="ml-auto">
                <b-nav-item
                    v-if="loggedIn"
                    class="ml-2 mr-2"
                    @click.stop="setActiveView('help')"
                >
          <span slot="button-content">
            <font-awesome-icon icon="question-circle"/>
          </span>
                </b-nav-item>
                <b-nav-form>
                    <b-form-select
                        v-model="locale"
                        :options="options"
                    />
                </b-nav-form>
                <b-nav-item-dropdown
                    v-if="loggedIn"
                    variant="link"
                    right
                    no-caret
                    class="logout ml-2 mr-1"
                >
          <span slot="button-content">
            <font-awesome-icon icon="user"/>
          </span>
                    <b-dropdown-item
                        disabled
                        class="tip-light"
                        v-if="isInitialized"
                    >
                        {{ $t('menu.signed_in') }}
                        <br>
                        <strong>{{ user.first_name }} {{ user.last_name }}</strong>
                        <br>
                        <strong>{{ user.company.name }}</strong>
                    </b-dropdown-item>
                    <b-dropdown-divider/>
                    <b-dropdown-item
                        href="#"
                        @click="setActiveView('user-account')"
                    >
                        {{ $t('menu.user_account') }}
                    </b-dropdown-item>
                    <b-dropdown-item
                        href="#"
                        @click="logout"
                    >
                        {{ $t('menu.logout') }}
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-if="userCompanies && userCompanies.length > 0"
                        href="#"
                        @click="changeCompany"
                    >
                        {{ $t('menu.change_company') }}
                    </b-dropdown-item>
                </b-nav-item-dropdown>
            </b-nav>
        </b-navbar>

        <transition name="fade">
            <div
                v-if="loggedIn && isInitialized"
                class="col-sm-12 nopads main-content"
            >
                <!-- Sidemenu -->
                <div class="sidebar-container nopads">
                    <b-nav
                        vertical
                        class="sidebar"
                        :class="{ toggled: sidebarShrink }"
                    >
                        <b-nav-item @click="setActiveView('map')" v-if="isObserver || isAdmin || isWorker || isMapViewer">
                            <div
                                id="map"
                                slot="button-content"
                                class="single-item"
                                :class="{ 'selected-menu-item' : isView('map') }"
                            >
                                <font-awesome-icon
                                    fixed-width
                                    icon="map"
                                />
                                <span style="font-size: .95em">{{ $t('menu.map') }}</span>

                            </div>
                        </b-nav-item>

                        <b-nav-item @click="setActiveView('favorites')" v-if="isObserver">
                            <div
                                id="map"
                                slot="button-content"
                                class="single-item"
                                :class="{ 'selected-menu-item' : isView('favorites') }"
                            >
                                <font-awesome-icon
                                    fixed-width
                                    icon="star"
                                />
                                <span style="font-size: .95em">{{ $t('menu.favorites') }}</span>
                            </div>
                        </b-nav-item>

                        <b-nav-item
                            v-if="!isAdmin && !isObserver && isWorker"
                            @click="setActiveView('workAssignmentsUser')"
                        >
                            <div
                                id="my_assignments"
                                slot="button-content"
                                class="single-item"
                                :class="{ 'selected-menu-item' : isView('workAssignmentsUser') }"
                            >
                                <font-awesome-icon
                                    fixed-width
                                    icon="tasks"
                                />
                                <span style="font-size: .95em;">{{ $t('menu.work_assignment') }}</span>
                            </div>
                        </b-nav-item>

                        <b-nav-item-dropdown
                            v-if="isAdmin || isObserver || isWorker || isMapViewer"
                            id="trips"
                            boundary="window"
                            no-caret
                            dropright
                            @click="setActiveView('searchTrips')"
                        >
                             <span
                                 slot="button-content"
                                 class="menu-title"
                                 :class="{ 'selected-menu-item' : isGroupOpen(tripViews) }"
                             >
                              <font-awesome-icon
                                  fixed-width
                                  icon="route"
                              />
                              <span>{{ $t('menu.fleet_trips') }}</span>
                            </span>

                            <b-dropdown-item
                                id="trip_search"
                                href="#"
                                @click="setActiveView('searchTrips')"
                            >
                                {{ $t('menu.search_trip') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                id="trip_report"
                                v-if="isAdmin || isObserver"
                                href="#"
                                @click="setActiveView('tripReports')"
                            >

                                {{ $t('menu.trip_report') }}
                            </b-dropdown-item>
                            <b-dropdown-divider v-if="isAdmin || isObserver"/>
                            <b-dropdown-item
                                id="deliveries"
                                v-if="isAdmin || isObserver"
                                href="#"
                                @click="setActiveView('deliveries')"
                            >

                                {{ $t('menu.delivery_notes') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                id="trip_search"
                                href="#"
                                @click="setActiveView('spreaderData')"
                            >
                                {{ $t('menu.spreader_data') }}
                            </b-dropdown-item>
                            <b-dropdown-divider v-if="isAdmin || isObserver"/>
                            <b-dropdown-item
                                id="observation_videos"
                                v-if="isAdmin || isObserver"
                                href="#"
                                @click="setActiveView('observationsVideos')"
                            >
                                {{ $t('menu.observation_recordings') }}
                            </b-dropdown-item>
                        </b-nav-item-dropdown>

                        <b-nav-item-dropdown
                            id="observations"
                            v-if="isAdmin || isObserver || isWorker || isMapViewer"
                            no-caret
                            boundary="window"
                            dropright
                            @click="setActiveView('observations')"
                        >
                            <span
                                slot="button-content"
                                class="menu-title"
                                :class="{ 'selected-menu-item' : isGroupOpen(observationViews) }"
                            >
                                <font-awesome-icon
                                  fixed-width
                                  icon="eye"
                                />
                                <span>{{ $t('menu.observations') }}</span>
                            </span>
                            <b-dropdown-item
                                id="observation_search"
                                href="#"
                                @click="setActiveView('observations')"
                            >
                                {{ $t('menu.observations') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                v-if="isObserver"
                                id="observation_search"
                                href="#"
                                @click="setActiveView('observation_reports')"
                            >
                                {{ $t('observation_reports.title') }}
                            </b-dropdown-item>
                        </b-nav-item-dropdown>

                        <b-nav-item-dropdown
                            id="areas"
                            v-if="isAdmin || isObserver || isWorker"
                            variant="link"
                            boundary="window"
                            dropright
                            no-caret
                        >
                            <span
                                slot="button-content"
                                class="menu-title"
                                :class="{ 'selected-menu-item' : isGroupOpen(infraViews) }"
                            >
                              <font-awesome-icon
                                  fixed-width
                                  icon="draw-polygon"
                              />
                              <span>{{ $t('menu.infra') }}</span>
                            </span>
                            <b-dropdown-item
                                id="street_lights"
                                v-if="isObserver || isWorker"
                                href="#"
                                @click="setActiveView('streetLights')"
                            >
                                {{ $t('menu.street_lights') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                id="sewer_liners"
                                v-if="isObserver || isWorker"
                                href="#"
                                @click="setActiveView('sewerLines')"
                            >
                                {{ $t('menu.sewer_lines') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                id="sewer_drains"
                                v-if="isObserver || isWorker"
                                href="#"
                                @click="setActiveView('sewerDrains')"
                            >
                                {{ $t('menu.sewer_drains') }}
                            </b-dropdown-item>
                            <b-dropdown-divider/>
                            <b-dropdown-item
                                id="road_signs"
                                v-if="isAdmin || isObserver || isWorker"
                                href="#"
                                @click="setActiveView('roadSigns')"
                            >
                                {{ $t('menu.traffic_signs') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                id="bridges"
                                v-if="isAdmin || isObserver || isWorker"
                                href="#"
                                @click="setActiveView('bridges')"
                            >
                                {{ $t('menu.bridges') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                id="culverts"
                                v-if="isAdmin || isObserver || isWorker"
                                href="#"
                                @click="setActiveView('culverts')"
                            >
                                {{ $t('menu.culverts') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                id="storages"
                                v-if="isAdmin || isObserver || isWorker"
                                href="#"
                                @click="setActiveView('materialStorageSearch')"
                            >
                                {{ $t('menu.material_storages') }}
                            </b-dropdown-item>
                            <b-dropdown-divider/>
                            <b-dropdown-item
                                id="areas_map"
                                href="#"
                                @click="setActiveView('areas')"
                            >
                                {{ $t('menu.areas') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                id="area_equipment"
                                href="#"
                                @click="setActiveView('areaEquipment')"
                            >
                                {{ $t('menu.area_equipment') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                id="area_groups"
                                href="#"
                                v-if="isAdmin || isObserver"
                                @click="setActiveView('areaGroups')"
                            >
                                {{ $t('menu.area_groups') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                id="area_work"
                                href="#"
                                v-if="isAdmin || isObserver"
                                @click="setActiveView('areaWork')"
                            >
                                {{ $t('menu.area_work') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                id="area_reports"
                                href="#"
                                @click="setActiveView('areaReports')"
                            >
                                {{ $t('menu.area_report') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                id="area_defects"
                                href="#"
                                @click="setActiveView('areaDefects')"
                            >
                                {{ $t('menu.area_defect') }}
                            </b-dropdown-item>
                        </b-nav-item-dropdown>

                        <b-nav-item-dropdown
                            id="inspections"
                            v-if="isObserver || isWorker"
                            no-caret
                            boundary="window"
                            @click="setActiveView('inspections')"
                            dropright
                        >
                            <span
                                slot="button-content"
                                class="menu-title"
                                :class="{ 'selected-menu-item' : isGroupOpen(safetyViews) }"
                            >
                              <font-awesome-icon
                                  fixed-width
                                  icon="clipboard-check"
                              />
                              <span>{{ $t('menu.safety') }}</span>
                            </span>
                            <b-dropdown-item
                                id="user_alerts"
                                href="#"
                                @click="setActiveView('userAlerts')"
                            >
                                {{ $t('menu.user_alerts') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                id="danger_reports"
                                href="#"
                                @click="setActiveView('dangerReports')"
                            >
                                {{ $t('menu.danger_reports') }}
                            </b-dropdown-item>
                            <b-dropdown-divider/>
                            <b-dropdown-item
                                id="inspection_wizard"
                                href="#"
                                @click="setActiveView('inspectionWizard')"
                            >
                                {{ $t('menu.new_inspection') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                id="inspection_results"
                                v-if="isAdmin || isObserver"
                                href="#"
                                @click="setActiveView('inspectionResults')"
                            >
                                {{ $t('menu.inspection_results') }}
                            </b-dropdown-item>
                            <b-dropdown-divider/>
                            <b-dropdown-item
                                id="inspection_templates"
                                href="#"
                                @click="setActiveView('inspectionTemplates')"
                            >
                                {{ $t('menu.fleet_templates') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                id="inspectables"
                                href="#"
                                @click="setActiveView('inspectables')"
                            >
                                {{ $t('menu.fleet_inspectables') }}
                            </b-dropdown-item>
                            <b-dropdown-divider/>
                            <b-dropdown-item
                                v-if="isObserver"
                                id="contractor_reports"
                                href="#"
                                @click="setActiveView('contractorReports')"
                            >
                                {{ $t('menu.contractor_reports') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                v-if="isObserver"
                                id="contract_user_report"
                                href="#"
                                @click="setActiveView('contractUserReports')"
                            >
                                {{ $t('menu.contract_user_reports') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                id="contractor_approvals"
                                v-if="isObserver"
                                href="#"
                                @click="setActiveView('subcontractorApprovals')"
                            >
                                {{ $t('menu.subcontractor_approvals') }}
                            </b-dropdown-item>

                            <b-dropdown-divider/>
                            <!-- General Guides -->
                            <b-dropdown-item
                                v-if="isObserver"
                                id="generalGuides"
                                href="#"
                                @click="setActiveView('generalGuides')"
                            >
                                {{ $t('menu.general_guides') }}
                            </b-dropdown-item>
                        </b-nav-item-dropdown>

                        <b-nav-item-dropdown
                            id="management"
                            v-if="isAdmin || isObserver || isMapViewer"
                            variant="link"
                            boundary="window"
                            dropright
                            no-caret
                        >
                            <span
                                slot="button-content"
                                class="menu-title"
                                :class="{ 'selected-menu-item' : isGroupOpen(managementViews) }"
                            >
                              <font-awesome-icon
                                  fixed-width
                                  icon="user-tie"
                              />
                              <span>{{ $t('menu.supervisor') }}</span>
                            </span>
                            <b-dropdown-item
                                class="hidden"
                                v-if="!isMapViewer"
                                id="work_reports"
                                href="#"
                                @click="setActiveView('workReports')"
                            >
                                {{ $t('menu.work_report') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                class="hidden"
                                href="#"
                                @click="setActiveView('forestWorkReports')"
                            >
                                {{ $t('menu.forest_work_report') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                href="#"
                                @click="setActiveView('efficiencyByContract')"
                            >
                                {{ $t('menu.forest_contract_eff') }}
                            </b-dropdown-item>
                            <b-dropdown-divider/>
                            <b-dropdown-item
                                v-if="!isMapViewer"
                                id="work_permissions"
                                href="#"
                                @click="setActiveView('workPermissions')"
                            >
                                {{ $t('menu.work_permission') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                v-if="!isMapViewer"
                                id="work_assignments"
                                href="#"
                                @click="setActiveView('workAssignments')"
                            >
                                {{ $t('menu.work_assignment') }}
                            </b-dropdown-item>
                            <b-dropdown-divider v-if="!isMapViewer"/>
                            <b-dropdown-item
                                v-if="!isMapViewer"
                                id="contracts"
                                href="#"
                                @click="setActiveView('contracts')"
                            >
                                {{ $t('menu.contracts') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                id="diary"
                                href="#"
                                @click="setActiveView('workDiary')"
                            >
                                {{ $t('contracts.external.work_diary') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                v-if="!isMapViewer"
                                id="calendar"
                                href="#"
                                @click="setActiveView('calendar')"
                            >
                                {{ $t('menu.calendar') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                v-if="!isMapViewer"
                                id="invoicing"
                                href="#"
                                @click="setActiveView('invoicing')"
                            >
                                {{ $t('menu.invoicing') }}
                            </b-dropdown-item>
                            <b-dropdown-divider v-if="!isMapViewer"/>
                            <b-dropdown-item
                                v-if="!isMapViewer"
                                id="work_time"
                                href="#"
                                @click="setActiveView('workTime')"
                            >
                                {{ $t('menu.work_time') }}
                            </b-dropdown-item>
                            <b-dropdown-divider v-if="!isMapViewer"/>
                            <b-dropdown-item
                                v-if="!isMapViewer"
                                id="additional_costs"
                                href="#"
                                @click="setActiveView('additionalCosts')"
                            >
                                {{ $t('menu.additional_cost') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                v-if="!isMapViewer"
                                class="hidden"
                                id="weighing_notes"
                                href="#"
                                @click="setActiveView('weighingNotes')"
                            >
                                {{ $t('menu.weighing_notes') }}
                            </b-dropdown-item>
                            <b-dropdown-divider v-if="!isMapViewer"/>
                            <b-dropdown-item
                                v-if="!isMapViewer"
                                id="certificates"
                                href="#"
                                @click="setActiveView('certificates')"
                            >
                                {{ $t('certificate.user_certificates') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                v-if="!isMapViewer"
                                id="certificate_report"
                                href="#"
                                @click="setActiveView('certificate_report')"
                            >
                                {{ $t('menu.certificate_report') }}
                            </b-dropdown-item>
                        </b-nav-item-dropdown>

                        <b-nav-item-dropdown
                            id="fleet"
                            v-if="isAdmin || isObserver || isWorker"
                            variant="link"
                            boundary="window"
                            no-caret
                            dropright
                            class="active"
                        >
                            <span
                                slot="button-content"
                                class="menu-title"
                                :class="{ 'selected-menu-item' : isGroupOpen(vehicleViews) }"
                            >
                              <font-awesome-icon
                                  fixed-width
                                  icon="truck"
                              />
                              <span>{{ $t('menu.fleet') }}</span>
                            </span>
                            <b-dropdown-item
                                id="fleet_search"
                                href="#"
                                @click="setActiveView('fleet')"
                            >
                                {{ $t('menu.fleet_register') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                id="tracking_device"
                                v-if="isAdmin || isObserver"
                                href="#"
                                @click="setActiveView('trackingDevices')"
                            >
                                {{ $t('menu.tracking_devices') }}
                            </b-dropdown-item>
                            <b-dropdown-divider/>
                            <!--            <b-dropdown-item-->
                            <!--                    href="#"-->
                            <!--                    @click="setActiveView('fleetInspections')"-->
                            <!--            >-->
                            <!--              {{ $t('menu.fleet_inspections') }}-->
                            <!--            </b-dropdown-item>-->
                            <b-dropdown-item
                                id="fleet_defects"
                                href="#"
                                @click="setActiveView('fleetDefects')"
                            >
                                {{ $t('menu.fleet_defects') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                id="fleet_allocation"
                                href="#"
                                @click="setActiveView('fleetAllocation')"
                            >
                                {{ $t('menu.fleet_allocation') }}
                            </b-dropdown-item>
                        </b-nav-item-dropdown>

                        <!-- Material stations -->
                        <b-nav-item-dropdown
                            id="stations"
                            v-if="isAdmin || isObserver || isWorker"
                            variant="link"
                            boundary="window"
                            dropright
                            no-caret
                        >
                            <span
                                slot="button-content"
                                class="menu-title"
                                :class="{ 'selected-menu-item' : isGroupOpen(stationViews) }"
                            >
                              <font-awesome-icon
                                  fixed-width
                                  icon="industry"
                              />
                              <span>{{ $t('menu.stations') }}</span>
                            </span>
                            <b-dropdown-item
                                id="station_search"
                                href="#"
                                @click="setActiveView('stations')"
                            >
                                {{ $t('menu.material_stations') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                id="station_deliveries"
                                href="#"
                                @click="setActiveView('deliveryNotes')"
                            >
                                {{ $t('menu.delivery_notes') }}
                            </b-dropdown-item>
                            <b-dropdown-divider/>
                            <!--
                            <b-dropdown-item
                                id="scales"
                                v-if="isAdmin || isObserver"
                                href="#"
                                @click="setActiveView('scales')"
                            >
                              {{ $t('menu.scales') }}
                            </b-dropdown-item>
                            -->
                            <b-dropdown-item
                                id="unknown_notes"
                                href="#"
                                @click="setActiveView('unknownNotes')"
                            >
                                {{ $t('menu.unknown_notes') }}
                            </b-dropdown-item>
                        </b-nav-item-dropdown>

                        <!-- Settings-->
                        <b-nav-item-dropdown
                            id="settings"
                            v-if="isObserver || isSuperUser || isWorker"
                            variant="link"
                            boundary="window"
                            dropright
                        >
                            <span
                                slot="button-content"
                                class="menu-title"
                                :class="{ 'selected-menu-item' : isGroupOpen(settingsViews) }"
                            >
                              <font-awesome-icon
                                  fixed-width
                                  icon="cogs"
                              />
                              <span>{{ $t('menu.settings') }}</span>
                            </span>
                            <b-dropdown-item
                                v-if="isObserver"
                                id="notifications"
                                href="#"
                                @click="setActiveView('notificationLists')"
                            >
                                {{ $t('menu.notification_lists') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                id="dataImport"
                                v-if="isAdmin || isObserver || isWorker"
                                href="#"
                                @click="setActiveView('dataImport')"
                            >
                                {{ $t('menu.data_import') }}
                            </b-dropdown-item>
                            <b-dropdown-divider/>
                            <b-dropdown-item
                                v-if="isObserver"
                                id="task_types"
                                href="#"
                                @click="setActiveView('taskTypes')"
                            >
                                {{ $t('menu.task_types') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                v-if="isSuperUser"
                                id="conversion_model_task_types"
                                href="#"
                                @click="setActiveView('conversionModelTaskTypes')"
                            >
                                {{ $t('menu.task_types_conversion_table') }}
                            </b-dropdown-item>
                            <b-dropdown-divider/>
                            <b-dropdown-item
                                id="efficiency"
                                href="#"
                                @click="setActiveView('efficiency')"
                            >
                                {{ $t('menu.forest_work_eff_conf') }}
                            </b-dropdown-item>
                            <b-dropdown-divider v-if="isObserver || isSuperUser"/>
                                <b-dropdown-item
                                    v-if="isObserver"
                                    id="price_lists"
                                    href="#"
                                    @click="setActiveView('priceLists')"
                                >
                                    {{ $t('menu.price_lists') }}
                                </b-dropdown-item>
                                <b-dropdown-item
                                    v-if="isSuperUser"
                                    href="#"
                                    @click="setActiveView('conversionModels')"
                                >
                                    {{ $t('menu.conversion_models') }}
                                </b-dropdown-item>
                            <b-dropdown-divider v-if="isObserver"/>
                            <b-dropdown-item
                                id="default_inspections"
                                v-if="isObserver"
                                href="#"
                                @click="setActiveView('defaultInspections')"
                            >
                                {{ $t('menu.default_inspections') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                id="default_task_types"
                                v-if="isObserver"
                                href="#"
                                @click="setActiveView('defaultTaskTypes')"
                            >
                                {{ $t('menu.default_task_types') }}
                            </b-dropdown-item>
                            <b-dropdown-divider v-if="isObserver || isWorker"/>
                            <b-dropdown-item
                                id="observation_collections"
                                v-if="isObserver || isWorker"
                                href="#"
                                @click="setActiveView('observationCollections')"
                            >
                                {{ $t('menu.observation_collections') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                id="contract_collections"
                                class="hidden"
                                v-if="isObserver"
                                href="#"
                                @click="setActiveView('contractCollections')"
                            >
                                {{ $t('menu.contract_collections') }}
                            </b-dropdown-item>
                        </b-nav-item-dropdown>

                        <b-nav-item-dropdown
                            id="admin"
                            v-if="isAdmin"
                            variant="link"
                            no-caret
                            dropright
                            boundary="window"
                        >
                            <span
                                slot="button-content"
                                class="menu-title"
                                :class="{ 'selected-menu-item' : isGroupOpen(adminViews) }"
                            >
                              <font-awesome-icon
                                  fixed-width
                                  icon="cog"
                              />
                              <span> {{ $t('menu.admin') }} </span>
                            </span>
                            <b-dropdown-item
                                id="obs_groups"
                                v-if="isAdmin"
                                href="#"
                                @click="setActiveView('observationGroups')"
                            >
                                {{ $t('menu.observation_groups') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                id="obs_types"
                                v-if="isAdmin"
                                href="#"
                                @click="setActiveView('observationTypes')"
                            >
                                {{ $t('menu.observation_types') }}
                            </b-dropdown-item>
                            <b-dropdown-divider/>
                            <b-dropdown-item
                                id="material_types"
                                v-if="isAdmin"
                                href="#"
                                @click="setActiveView('materialTypes')"
                            >
                                {{ $t('menu.material_types') }}
                            </b-dropdown-item>
                            <b-dropdown-divider/>
                            <b-dropdown-item
                                id="users"
                                href="#"
                                @click="setActiveView('users')"
                            >
                                {{ $t('menu.admin_users') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                id="api_keys"
                                href="#"
                                @click="setActiveView('api-keys')"
                            >
                                {{ $t('menu.api_keys') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                id="external_ids"
                                href="#"
                                @click="setActiveView('externalContractIds')"
                            >
                                {{ $t('menu.external_ids') }}
                            </b-dropdown-item>
                            <b-dropdown-divider/>
                            <b-dropdown-item
                                id="certificate_types"
                                href="#"
                                @click="setActiveView('certificateTypes')"
                            >
                                {{ $t('certificate.type_title') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                id="license_reports"
                                href="#"
                                @click="setActiveView('licenseReport')"
                            >
                                {{ $t('menu.license_report') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                id="license_report_admin"
                                @click="setActiveView('licenseAdminView')"
                            >
                                {{ $t('menu.license_admin_report') }}
                            </b-dropdown-item>
                            <b-dropdown-divider/>
                            <b-dropdown-item
                                id="companies"
                                href="#"
                                @click="setActiveView('companies')"
                            >
                                {{ $t('menu.admin_companies') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                id="company_relationships"
                                href="#"
                                @click="setActiveView('companyRelationships')"
                            >
                                {{ $t('menu.admin_company_relationships') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                id="pricing_models"
                                href="#"
                                @click="setActiveView('pricingModels')"
                            >
                                {{ $t('menu.pricing_models') }}
                            </b-dropdown-item>
                            <b-dropdown-divider/>
                            <b-dropdown-item
                                id="generate_summaries"
                                href="#"
                                @click="setActiveView('contractTripSummaryGenerator')"
                            >
                                {{ $t('menu.trip_summary_generator') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                id="generate_spreader_date"
                                href="#"
                                @click="setActiveView('spreaderDataGenerator')"
                            >
                                {{ $t('menu.generate_spreader_data') }}
                            </b-dropdown-item>

                        </b-nav-item-dropdown>

                        <b-nav-item
                            id="additional_costs_worker"
                            v-if="!isAdmin && !isObserver && isWorker"
                            @click="setActiveView('additionalCostsUser')"
                        >
                            <div
                                slot="button-content"
                                class="single-item"
                                :class="{ 'selected-menu-item' : isView('additionalCostsUser') }"
                            >
                                <font-awesome-icon
                                    fixed-width
                                    icon="euro-sign"
                                />
                                <span style="font-size: .95em;">{{ $t('menu.additional_cost') }}</span>
                            </div>
                        </b-nav-item>

                        <!-- Ticket system -->
                        <b-nav-item
                            id="tickets"
                            class="hidden"
                            v-if="isObserver && ticketEnabled"
                            @click="showTicketSystem">
                            <div
                                slot="button-content"
                                class="single-item"
                                :class="{ 'selected-menu-item' : isView('ticketSystem') }"
                            >
                                <font-awesome-icon
                                    fixed-width
                                    style="margin-left: 2px"
                                    icon="ticket-alt"
                                />
                                <span style="font-size: 1em">{{ $t('menu.tickets') }}</span>
                            </div>
                        </b-nav-item>

                        <b-nav-item-dropdown
                            id="company_users"
                            v-if="isSuperUser && !isAdmin"
                            variant="link"
                            no-caret
                            dropright
                            boundary="window"
                        >
                            <div
                                slot="button-content"
                                class="single-item"
                                :class="{ 'selected-menu-item' : isGroupOpen(superUserViews) }"
                            >
                                <font-awesome-icon
                                    fixed-width
                                    icon="user"
                                />
                                <span style="font-size: .9em;">{{ $t('menu.admin_users') }}</span>
                            </div>
                            <b-dropdown-item
                                id="company_users_search"
                                v-if="isSuperUser"
                                @click="setActiveView('users')"
                            >
                                {{ $t('menu.admin_users') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                id="company_license_report"
                                href="#"
                                @click="setActiveView('licenseReport')"
                            >
                                {{ $t('menu.license_report') }}
                            </b-dropdown-item>
                        </b-nav-item-dropdown>

                    </b-nav>
                </div>

                <!-- Content placeholder -->
                <div
                    class="content"
                    :class="{ toggled: sidebarShrink }"
                >
                    <transition name="fade">
                        <my-account
                            v-if="isView('user-account')"
                            :user="user"
                        />
                    </transition>
                    <transition name="fade">
                        <map-view
                            v-if="isView('map')"
                            :user="user"
                            :is-admin="isAdmin"
                            :is-observer="isObserver"
                            :is-worker="isWorker"
                            :is-map-viewer="isMapViewer"
                            :center="mapCenter"
                            :zoom="mapZoom"
                            :logoutEvent="logoutEvent"
                            @onStoreMapCenter="saveMapCenter"
                        />
                    </transition>
                    <transition name="fade">
                        <favorites
                            v-if="isView('favorites')"
                            @shortcutClicked="openShortcut"
                        />
                    </transition>
                    <transition name="fade">
                        <tasktypes
                            v-if="isView('taskTypes')"
                            :is-admin="isAdmin"
                        />
                    </transition>
                    <transition name="fade">
                        <conversion-task-type-models
                            v-if="isView('conversionModelTaskTypes')"
                            :is-admin="isAdmin"
                        />
                    </transition>
                    <transition name="fade">
                        <work-time-view
                            v-if="isView('workTime')"
                            :user="user"
                        />
                    </transition>
                    <transition name="fade">
                        <companies
                            v-if="isView('customers') || isView('companies')"
                            :is-admin="isAdmin"
                        />
                    </transition>
                    <transition name="fade">
                        <company-relationships
                            v-if="isView('companyRelationships')"
                            :is-admin="isAdmin"
                        />
                    </transition>
                    <transition name="fade">
                        <inspection-wizard
                            v-if="isView('inspectionWizard')"
                            :inspection-item="inspectionItem"
                            :user="user"
                            :is-admin="isAdmin"
                            :is-observer="isObserver"
                        />
                    </transition>
                    <transition name="fade">
                        <contracts
                            v-if="isView('contracts')"
                            :user="user"
                            :is-map-viewer="isMapViewer"
                            :isObserver="isObserver"
                            :pre-selected-contract="shortcutItem"
                        />
                    </transition>
                    <transition name="fade">
                        <contract-diaries
                            v-if="isView('workDiary')"
                            :user="user"
                            :is-map-viewer="isMapViewer"
                            :pre-selected-contract="shortcutItem"
                        />
                    </transition>
                    <transition name="fade">
                        <orders
                            v-if="isView('orders')"
                            :user="user"
                        />
                    </transition>
                    <transition name="fade">
                        <invoicing
                            v-if="isView('invoicing')"
                            :user="user"
                        />
                    </transition>
                    <transition name="fade">
                        <contract-calendar-container
                            v-if="isView('calendar')"
                            :user="user"
                        />
                    </transition>
                    <transition name="fade">
                        <users
                            v-if="isView('users')"
                            :is-admin="isAdmin"
                            :is-super-user="isSuperUser"
                            :user-account="user"
                        />
                    </transition>
                    <transition name="fade">
                        <external-contract-ids
                            v-if="isView('externalContractIds')"
                            :user="user"
                        />
                    </transition>
                    <transition name="fade">
                        <api-keys
                            v-if="isView('api-keys')"
                            :is-admin="isAdmin"
                            :is-super-user="isSuperUser"
                            :user-account="user"
                        />
                    </transition>
                    <transition name="fade">
                        <areas
                            v-if="isView('areas')"
                            :user="user"
                            :is-admin="isAdmin"
                        />
                    </transition>
                    <transition name="fade">
                        <area-equipment
                            v-if="isView('areaEquipment')"
                            :is-admin="isAdmin"
                            :is-observer="isObserver"
                        />
                    </transition>
                    <transition name="fade">
                        <area-groups
                            v-if="isView('areaGroups')"
                            :is-admin="isAdmin"
                            :user="user"
                        />
                    </transition>
                    <transition name="fade">
                        <area-work
                            v-if="isView('areaWork')"
                        />
                    </transition>
                    <transition name="fade">
                        <trips
                            v-if="isView('searchTrips')"
                            :user="user"
                            :is-admin="isAdmin"
                            :is-observer="isObserver"
                            :is-map-viewer="isMapViewer"
                            :is-worker="isWorker"
                            :pre-selected-contract="shortcutItem"
                        />
                    </transition>
                    <transition name="fade">
                        <contract-trip-summaries
                            v-if="isView('tripReports')"
                            :user="user"
                            :pre-selected-contract="shortcutItem"
                        />
                    </transition>
                    <transition name="fade">
                        <observations
                            v-if="isView('observations')"
                            :user="user"
                            :is-admin="isAdmin"
                            :is-map-viewer="isMapViewer"
                            :is-observer="isObserver"
                        />
                    </transition>
                    <transition name="fade">
                        <observation-reports
                            v-if="isView('observation_reports')"
                            :user="user"
                        />
                    </transition>
                    <transition name="fade">
                        <material-storage-search
                            v-if="isView('materialStorageSearch')"
                            :user="user"
                            :is-admin="isAdmin"
                            :is-observer="isObserver"
                        />
                    </transition>
                    <transition name="fade">
                        <observationGroups
                            v-if="isView('observationGroups')"
                            :user="user"
                            :is-admin="isAdmin"
                        />
                    </transition>
                    <transition name="fade">
                        <observationTypes v-if="isView('observationTypes')"/>
                    </transition>
                    <transition name="fade">
                        <material-types
                            v-if="isView('materialTypes')"
                            :user="user"
                            :is-admin="isAdmin"
                        />
                    </transition>
                    <transition name="fade">
                        <vehicles
                            v-if="isView('fleet')"
                            :is-admin="isAdmin"
                        />
                    </transition>
                    <transition name="fade">
                        <tracking-devices
                            v-if="isView('trackingDevices')"
                            :is-admin="isAdmin"
                        />
                    </transition>

                    <transition name="fade">
                        <inspections
                            v-if="isView('inspectionTemplates')"
                            :is-admin="isAdmin"
                        />
                    </transition>
                    <transition name="fade">
                        <inspection-reports
                            v-if="isView('inspectionResults')"
                            :user="user"
                        />
                    </transition>
                    <transition name="fade">
                        <inspectables
                            v-if="isView('inspectables')"
                        />
                    </transition>
                    <transition name="fade">
                        <contractor-reports
                            v-if="isView('contractorReports')"
                        />
                    </transition>
                    <transition name="fade">
                        <contract-user-reports
                            v-if="isView('contractUserReports')"
                        />
                    </transition>
                    <transition name="fade">
                        <VehicleDefects
                            v-if="isView('fleetDefects')"
                            :is-admin="isAdmin"
                            :is-observer="isObserver"
                        />
                    </transition>
                    <transition name="fade">
                        <vehicle-allocation
                            v-if="isView('fleetAllocation')"
                            :is-admin="isAdmin"
                            :is-observer="isObserver"
                        />
                    </transition>
                    <transition name="fade">
                        <area-reports
                            v-if="isView('areaReports')"
                            :is-admin="isAdmin"
                            :user-id="user.id"
                        />
                    </transition>
                    <transition name="fade">
                        <area-defects
                            v-if="isView('areaDefects')"
                            :is-admin="isAdmin"
                            :is-observer="isObserver"
                        />
                    </transition>
                    <transition name="fade">
                        <about v-if="isView('help')"/>
                    </transition>
                    <transition name="fade">
                        <observations-videos
                            v-if="isView('observationsVideos')"
                            :is-admin="isAdmin"
                            :is-observer="isObserver"
                            :user="user"
                        />
                    </transition>
                    <transition name="fade">
                        <street-lights-view
                            v-if="isView('streetLights')"
                            :user="user"
                        />
                    </transition>
                    <transition name="fade">
                        <data-importer
                            v-if="isView('dataImport')"/>
                    </transition>
                    <transition name="fade">
                        <storm-sewer-lines
                            v-if="isView('sewerLines')"
                            :user="user"
                        />
                    </transition>
                    <transition name="fade">
                        <storm-sewer-drains
                            v-if="isView('sewerDrains')"
                            :user="user"
                        />
                    </transition>
                    <transition name="fade">
                        <road-signs
                            v-if="isView('roadSigns')"
                            :user="user"
                            :is-admin="isAdmin"
                        />
                    </transition>
                    <transition name="fade">
                        <bridges
                            v-if="isView('bridges')"
                            :set-inspection-params="setInspectionParams"
                        />
                    </transition>
                    <transition name="fade">
                        <culverts
                            v-if="isView('culverts')"
                            :set-inspection-params="setInspectionParams"
                        />
                    </transition>
                    <transition name="fade">
                        <work-reports
                            v-if="isView('workReports')"
                            :is-admin="isAdmin"
                            :user="user"
                        />
                    </transition>
                    <transition name="fade">
                        <forest-work-reports
                            v-if="isView('forestWorkReports')"
                            :is-admin="isAdmin"
                            :user="user"
                        />
                    </transition>
                    <transition name="fade">
                        <efficiency
                            v-if="isView('efficiency')"
                            :is-admin="isAdmin"
                        />
                    </transition>
                    <transition name="fade">
                        <efficiency-by-contract
                            v-if="isView('efficiencyByContract')"
                            :is-admin="isAdmin"
                            :is-observer="isObserver"
                        />
                    </transition>
                    <transition name="fade">
                        <certificate-types
                            v-if="isView('certificateTypes')"
                            :is-admin="isAdmin"
                        />
                    </transition>
                    <transition name="fade">
                        <users-certificates
                            v-if="isView('certificates')"
                            :is-admin="isAdmin"
                        />
                    </transition>
                    <transition name="fade">
                        <certificate-report
                            v-if="isView('certificate_report')"
                        />
                    </transition>
                    <transition name="fade">
                        <subcontractor-applications
                            v-if="isView('subcontractorApprovals')"
                        />
                    </transition>
                    <transition name="fade">
                        <work-assignments
                            v-if="isView('workAssignments')"
                            :is-admin="isAdmin"
                            :is-observer="isObserver"
                            :user="user"
                        />
                    </transition>
                    <transition name="fade">
                        <danger-reports
                            v-if="isView('dangerReports')"
                            :is-observer="isObserver"
                            :is-admin="isAdmin"
                            :user="user"
                        />
                    </transition>
                    <transition name="fade">
                        <user-alerts
                            v-if="isView('userAlerts')"
                            :is-observer="isObserver"
                            :is-admin="isAdmin"
                        />
                    </transition>
                    <transition name="fade">
                        <contract-trip-summary-generator
                            v-if="isView('contractTripSummaryGenerator')"
                        />
                    </transition>
                    <transition name="fade">
                        <spreader-data-generator
                            v-if="isView('spreaderDataGenerator')"
                        />
                    </transition>
                    <transition name="fade">
                        <work-assignments-user v-if="isView('workAssignmentsUser')"/>
                    </transition>
                    <transition name="fade">
                        <additional-costs-user v-if="isView('additionalCostsUser')"/>
                    </transition>
                    <transition name="fade">
                        <notification-lists v-if="isView('notificationLists')"/>
                    </transition>
                    <transition name="fade">
                        <work-permissions
                            v-if="isView('workPermissions')"
                            :is-observer="isObserver"/>
                    </transition>
                    <transition name="fade">
                        <default-inspections v-if="isView('defaultInspections')"/>
                    </transition>
                    <transition name="fade">
                        <default-task-types v-if="isView('defaultTaskTypes')"/>
                    </transition>
                    <transition name="fade">
                        <material-stations
                            v-if="isView('stations')"
                            :is-observer="isObserver"
                        />
                    </transition>
                    <transition name="fade">
                        <material-delivery-notes
                            v-if="isView('deliveryNotes')"
                            :user="user"
                            :is-admin="isAdmin"
                            :is-observer="isObserver"
                            :login-time="loginTime"
                        />
                    </transition>
                    <transition name="fade">
                        <deliveries
                            v-if="isView('deliveries')"
                            :is-admin="isAdmin"
                            :is-observer="isObserver"
                        />
                    </transition>
                    <transition name="fade">
                        <external-scale-records
                            v-if="isView('unknownNotes')"
                            :is-observer="isObserver"
                        />
                    </transition>
                    <transition name="fade">
                        <weighing-notes
                            v-if="isView('weighingNotes')"
                            :is-admin="isAdmin"
                            :is-observer="isObserver"
                        />
                    </transition>
                    <transition name="fade">
                        <harja-messages
                            v-if="isView('harjaMessages')"
                            :is-admin="isAdmin"
                            :is-observer="isObserver"
                        />
                    </transition>
                    <transition name="fade">
                        <additional-costs
                            v-if="isView('additionalCosts')"
                            :user="user"
                            :is-admin="isAdmin"
                            :is-observer="isObserver"
                            :is-worker="isWorker"
                        />
                    </transition>
                    <transition name="fade">
                        <pricing-models
                            v-if="isView('pricingModels')"/>
                    </transition>
                    <transition name="fade">
                        <license-report
                            v-if="isView('licenseReport')"
                            :is-admin="isAdmin"
                        />
                    </transition>
                    <transition name="fade">
                        <license-admin-view
                            v-if="isView('licenseAdminView')"
                            :is-admin="isAdmin"
                        />
                    </transition>
                    <transition name="fade">
                        <ticket-system
                            v-if="ticketEnabled && isView('ticketSystem')"
                            :is-observer="isObserver"
                            :user="user"
                            :feedback-guid="feedbackGuid"
                            :has-company-ticket-url="ticketEnabled"
                        />
                    </transition>
                    <transition name="fade">
                        <price-lists
                            v-if="isView('priceLists')"
                        />
                    </transition>
                    <transition name="fade">
                        <observation-collections
                            v-if="isView('observationCollections')"
                        />
                    </transition>
                    <transition name="fade">
                        <contract-collections
                            v-if="isView('contractCollections')"
                        />
                    </transition>
                    <transition name="fade">
                        <conversion-models
                            v-if="isView('conversionModels')"
                        />
                    </transition>
                    <transition name="fade">
                        <general-guides
                            v-if="isView('generalGuides')"
                            :user="user"/>
                    </transition>
                    <transition name="fade">
                        <spreader-data
                            v-if="isView('spreaderData')"
                            :user="user"
                            :isAdmin="isAdmin"/>
                    </transition>
                    <b-alert
                        class="alert"
                        style="z-index: 5000 !important;"
                        :show="alertDismissCountDown"
                        dismissible
                        fade
                        :variant="alertVariant"
                        @dismissed="alertDismissCountDown=0"
                        @dismiss-count-down="alertCountDownChanged"
                    >
                        {{ alertMessage }}
                    </b-alert>
                </div>
            </div>
        </transition>

        <b-modal
            ref="companyModal"
            centered
            no-close-on-esc
            no-close-on-backdrop
            :hide-header-close="!allowCloseCompanyModal"
            hide-footer
            @close="allowCloseCompanyModal = false"
            :title="$t('common.select_company')">
            <div class="col-sm-12">
                <div
                    v-for="company in userCompanies"
                    :key="company.id"
                    class="company_selector"
                    @click.stop="fetchCompanyToken(company.id)">
                    {{ company.name }}
                </div>
            </div>
        </b-modal>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import GeneralGuides from './documents/GeneralGuides'
import LicenseAdminView from './licensereport/LicenseAdminView'
import {EventBus} from '@/event-bus'
import {app} from '@/main'
import MapView from './map/MapView'
import Vehicles from './vehicle/Vehicles'
import ApiKeys from './apikeys/ApiKeys'
import Inspections from './inspections/Inspections'
import InspectionReports from './inspections/InspectionReports'
import Inspectables from './inspections/inspectable/Inspectables'
import Users from './user/Users'
import Tasktypes from './tasktype/TaskTypes'
import Companies from './company/Companies'
import Contracts from './contract/Contracts'
import Invoicing from './invoice/Invoicing.vue'
import Orders from './order/Orders'
import Trips from './trip/Trips'
import Observations from './observation/Observations'
import ObservationGroups from './observation/group/ObservationGroups'
import ObservationTypes from './observation/type/ObservationTypes'
import ObservationCollections from "@/components/observation/collection/ObservationCollections";
import MaterialTypes from "./materials/type/MaterialTypes";
import MaterialDeliveryNotes from "@/components/materials/MaterialDeliveryNotes";
import CertificateTypes from './certificate/CertificateTypes'
import UsersCertificates from './certificate/UsersCertificates'
import Login from './user/Login'
import ResetPasswordRequest from './user/ResetPasswordRequest'
import {restApi} from './mixins/RestApiMixin'
import {userHelper} from './mixins/UserMixin'
import CompanyRelationships from './company/companyrelationship/CompanyRelationships'
import MyAccount from './user/MyAccount'
import About from './About'
import VehicleDefects from './vehicle/defect/VehicleDefects'
import VehicleAllocation from './vehicle/allocation/VehicleAllocation'
import Areas from './area/Areas'
import AreaEquipment from './area/areaequipment/AreaEquipment'
import AreaGroups from './area/group/AreaGroups'
import AreaReports from './area/areareport/AreaReports'
import ObservationsVideos from './observation/videos/ObservationVideos'
import WorkReports from './workreport/WorkReports'
import AreaDefects from './area/areadefect/AreaDefects'
import WorkAssignments from './workassignment/WorkAssignments'
import {utility} from './mixins/Utils'
import WorkAssignmentsUser from './workassignment/WorkAssignmentsUser'
import ContractTripSummaries from './tripsummary/ContractTripSummaries'
import ContractTripSummaryGenerator from './tripsummary/ContractTripSummaryGenerator'
import ForestWorkReports from "./forest/ForestWorkReports";
import TrackingDevices from "./trackingdevices/TrackingDevices";
import Efficiency from "./effconfiguration/Efficiency";
import EfficiencyByContract from "./forestefficiency/EfficiencyByContract";
import InspectionWizard from "./inspections/wizard/InspectionWizard";
import DangerReports from "./dangerreports/DangerReports";
import UserAlerts from "./user/alert/UserAlerts";
import WorkPermissions from "./workpermission/WorkPermissions";
import DefaultInspections from "./contract/DefaultInspections";
import DefaultTaskTypes from "./contract/DefaultTaskTypes";
import {mapHelper} from './mixins/MapMixin'
import ContractorReports from "@/components/contractorreport/ContractorReports";
import {
    register as registerForNotifications,
    unregister as unregisterFromNotifications,
} from "../modules/RoutaNotification";
import NotificationLists from "@/components/notifications/NotificationLists";
import MaterialStations from "@/components/materials/station/MaterialStations";
import ExternalScaleRecords from "@/components/materials/station/ExternalScaleRecords";
import RoadSigns from "@/components/roadsign/RoadSigns";
import Bridges from "@/components/bridge/Bridges";
import Culverts from "@/components/culvert/Culverts";
import AdditionalCosts from "@/components/additionalcost/AdditionalCosts";
import ConversionTaskTypeModels from "@/components/tasktype/conversion/ConversionTaskTypeModels";
import AdditionalCostsUser from "@/components/additionalcost/AdditionalCostsUser";
import PricingModels from "./pricingmodels/PricingModels";
import LicenseReport from "./licensereport/LicenseReport";
import ContractUserReports from "./contractorreport/contractusers/ContractUserReport";
import AreaWork from "./area/areawork/AreaWork";
import TicketSystem from "./louhi/TicketSystem";
import PriceLists from "./pricingmodels/pricelists/PriceLists";
import ConversionModels from "./pricingmodels/conversion/ConversionModels";
import CertificateReport from "./certificate/CertificateReport";
import Deliveries from "@/components/materials/Deliveries";
import MaterialStorageSearch from "./materialstorage/MaterialStorageSearch";
import SubcontractorApplications from "@/components/subcontractors/SubcontractorApplications";
import WeighingNotes from "./materials/station/WeighingNotes";
import HarjaMessages from "@/components/harja/HarjaMessages.vue";
import StormSewerLines from "@/components/stormsewers/StormSewerLines";
import StormSewerDrains from "@/components/stormsewers/StormSewerDrains";
import ContractCalendarContainer from "@/components/contractcalendar/ContractCalendarContainer";
import WorkTimeView from "@/components/worktime/new/WorkTime";
import DataImporter from "@/components/dataimport/DataImporter";
import SpreaderData from "@/components/materials/spreaderdata/SpreaderData";
import ObservationReports from "@/components/observation/reports/ObservationReports";
import ExternalContractIds from "@/components/contract/external/ExternalContractIds";
import ContractCollections from "@/components/contract/collection/ContractCollections";
import StreetLightsView from "@/components/streetlights/StreetLightsView";
import ContractDiaries from "@/components/contractreport/ContractDiaries";
import SpreaderDataGenerator from "@/components/materials/spreaderdata/SpreaderDataGenerator";
import Favorites from "@/components/favorites/Favorites";


export default {
    name: 'Office',
    components: {
        Favorites,
        SpreaderDataGenerator,
        ContractDiaries,
        StreetLightsView,
        ContractCollections,
        ExternalContractIds,
        ObservationReports,
        SpreaderData,
        DataImporter,
        WorkTimeView,
        ContractCalendarContainer,
        StormSewerLines,
        StormSewerDrains,
        WeighingNotes,
        HarjaMessages,
        SubcontractorApplications,
        GeneralGuides,
        LicenseAdminView,
        MaterialStorageSearch,
        Deliveries,
        CertificateReport,
        PriceLists,
        ConversionModels,
        TicketSystem,
        AreaWork,
        ContractUserReports,
        LicenseReport,
        PricingModels,
        AdditionalCostsUser,
        AdditionalCosts,
        Culverts,
        Bridges,
        RoadSigns,
        ExternalScaleRecords,
        MaterialStations,
        NotificationLists,
        ContractorReports,
        UserAlerts,
        DangerReports,
        InspectionWizard,
        Efficiency,
        TrackingDevices,
        WorkAssignmentsUser,
        AreaDefects,
        ObservationsVideos,
        AreaReports,
        Areas,
        ApiKeys,
        AreaEquipment,
        AreaGroups,
        MapView,
        Tasktypes,
        ConversionTaskTypeModels,
        Vehicles,
        VehicleAllocation,
        Inspections,
        InspectionReports,
        Inspectables,
        Users,
        Companies,
        CompanyRelationships,
        Contracts,
        Invoicing,
        Orders,
        Trips,
        Observations,
        ObservationGroups,
        ObservationTypes,
        ObservationCollections,
        MaterialTypes,
        MaterialDeliveryNotes,
        CertificateTypes,
        UsersCertificates,
        Login,
        ResetPasswordRequest,
        MyAccount,
        About,
        VehicleDefects,
        WorkReports,
        ForestWorkReports,
        WorkAssignments,
        ContractTripSummaries,
        ContractTripSummaryGenerator,
        EfficiencyByContract,
        WorkPermissions,
        DefaultInspections,
        DefaultTaskTypes,
    },
    mixins: [userHelper, restApi, utility, mapHelper],
    data() {
        return {
            allowCloseCompanyModal: false,
            TIMEOUT: 60 * 60 * 1000, // Inactivity time out
            inactivityStartTime: null,
            locale: this.getLanguage(),
            loginMessage: null,
            loggedIn: false,
            passwordReset: false,
            sidebarShrink: false,
            user: null,
            userCompanies: [],
            feedbackGuid: null,
            ticketEnabled: false,
            isAdmin: false,
            isObserver: false,
            isWorker: false,
            isMapViewer: false,
            isSuperUser: false,
            isInitialized: false,
            activeView: null,
            loading: false,
            alertDismissSecs: 5,
            alertDismissCountDown: 0,
            alertMessage: '',
            alertVariant: 'danger',
            loginTime: null,
            mapCenter: null,
            mapZoom: 12,
            inspectionItem: null,
            inactivityTimer: null,
            logoutEvent: false,
            options: [
                {value: 'fi', text: 'FI'},
                {value: 'en', text: 'EN'},
                {value: 'sv', text: 'SV'},
            ],
            adminViews: ['users', 'companies', 'workers', 'companies', 'companyRelationships', 'certificateTypes', 'contractTripSummaryGenerator', 'spreaderDataGenerator', 'observationGroups', 'observationTypes', 'materialTypes', 'pricingModels', 'licenseReport', 'licenseAdminView', 'externalContractIds'],
            superUserViews: ['users', 'licenseReport'],
            managementViews: ['customers', 'contracts', 'workDiary', 'calendar', 'workTime', 'certificates', 'certificateReport', 'dailyAllowances', 'mealAllowances', 'workPermissions', 'additionalCosts', 'workAssignments', 'harjaMessages', 'weighingNotes'],
            infraViews: ['materialStorageSearch', 'areas', 'areaEquipment', 'areaReports', 'areaGroups', 'areaWork', 'streetLights', 'roadSigns', 'bridges', 'culverts'],
            tripViews: ['searchTrips', 'tripReports', 'deliveries', 'spreaderData','observationsVideos'],
            observationViews: ['observations'],
            workReportViews: ['workReports'],
            forestReportViews: ['forestWorkReports', 'efficiency', 'efficiencyByContract'],
            vehicleViews: ['fleet', 'fleetInspections', 'trackingDevices'],
            settingsViews: ['notificationLists', 'efficiency', 'taskTypes', 'workTimeModes', 'costCenter', 'defaultInspections', 'defaultTaskTypes', 'priceLists', 'dataImport'],
            reportViews: ['areaReports'],
            safetyViews: ['userAlerts', 'dangerReports', 'inspectionWizard', 'inspectionResults', 'inspectionTemplates', 'inspectables', 'contractorReports', 'contractUserReports', 'subcontractorApprovals', 'generalGuides'],
            stationViews: ['stations', 'scales', 'deliveryNotes', 'unknownNotes' ],
            shortcutItem: null
        }
    },

    watch: {
        locale(val) {
            app.$i18n.locale = val
        },

        loggedIn: function (value) {
            if (value === true) {
                this.loginMessage = null
                this.initUser()
            } else {
                this.loading = false
            }
        }
    },

    created: function () {
        EventBus.$on('show-alert', (message, variant = 'danger') => this.showAlert(message, variant))
        window.addEventListener('beforeunload', async () => unregisterFromNotifications(this));
        // Style calculation for mobile browsers
        let vh = window.innerHeight + 50;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    },

    beforeDestroy: function () {
        EventBus.$off('show-alert', (message) => this.showAlert(message))
    },

    methods: {
        visibilityChange(evt, hidden) {
            if (!hidden && this.inactivityStartTime) {
                // check if inactive time out is passed
                let now = new Date();
                if (this.inactivityStartTime && (this.inactivityStartTime.getTime() + this.TIMEOUT < now.getTime())) {
                    if (this.inactivityTimer) {
                        clearTimeout(this.inactivityTimer)
                    }
                    this.timedLogout()
                }
            }
        },

        openShortcut(view, item) {
            this.setActiveView(view, item)
        },

        onMessage(message) {
            if (message.data) {
                this.makeToast(message.data.title, message.data.body, message.data.topic)
            }
        },

        makeToast(title, body, topic) {
            this.toastCount++
            this.$bvToast.toast(body, {
                title: title,
                autoHideDelay: topic === 1 ? 2 * 60 * 60 * 1000 : 10000,  // Emergency messages stay two hours, others 20 secs
                appendToast: true,
                variant: this.getToastVariant(topic),
                solid: true
            })
        },

        getToastVariant(topic) {
            switch (parseInt(topic)) {
                case 1:
                    return 'danger'
                case 2:
                    return 'warning'
            }
            return 'secondary'
        },

        resetPassword: function () {
            this.passwordReset = true
        },

        resetPasswordClose: function () {
            this.passwordReset = false
        },

        resetPasswordDone: function () {
            this.resetPasswordClose()
            this.$nextTick(() => this.$refs.login.showResetTip())
        },

        initUser: function () {
            this.loading = true
            this.restFetch(this.userInfoUrl, this.handleUserResponse)
        },

        handleUserResponse: function (response) {
            if (response && response.status === 200) {
                this.user = response.data
                // Check for user rights and prompt for company if necessary
                if (this.user.rights && this.user.rights.length > 0) {
                    // Resolve companies, for which user has roles
                    this.userCompanies = this.getUserCompanies()
                    if (this.userCompanies.length > 1) {
                        this.loading = false
                        this.allowCloseCompanyModal = false
                        this.$refs.companyModal.show()
                    } else if (this.userCompanies.length === 1) {
                        // Get token for the only company we have here
                        this.fetchCompanyToken(this.userCompanies[0].id)
                    }
                } else {
                    // Legacy roles
                    // TODO - When legacy is dropped, throw back to login screen if there are no user companies
                    this.initRoles()
                    if (!this.hasAdmin(this.user.roles) && !this.hasObserver(this.user.roles) && !this.hasWorker(this.user.roles)) {
                        this.toggleSidebar()
                    }
                    this.loading = false
                    this.centerToCompany()
                }
            } else {
                // User info initialization failed - throw the user out with an error message
                this.loading = false
                this.loginFailed()
            }
        },

        getUserCompanies: function () {
            let result = []
            if (this.user.rights && this.user.rights.length > 0) {
                this.user.rights.forEach(function (item) {
                    let existing = result.find(cmp => cmp.id === item.company.id)
                    if (!existing) {
                        result.push(item.company)
                    }
                })
            }
            return result
        },

        async fetchCompanyToken(companyId) {
            this.loading = true
            this.$refs.companyModal.hide()
            this.restFetch(this.companyTokenUrl + '/' + companyId, this.handleCompanyTokenResponse)
        },

        handleCompanyTokenResponse: function (response) {
            this.loginTime = new Date()
            this.loading = false
            if (response.data.token) {
                this.$store.commit('storeToken', response.data.token)
                this.user = response.data.user
                this.resetRoles()
                this.initTicketEnabled()
                this.initializeUI()
            } else {
                this.loginFailed()
            }
        },

        fetchCompanyFailed: function () {
            this.loginFailed()
        },

        initTicketEnabled() {
            this.ticketEnabled = this.user.api_key_types.ticket_system === true || process.env.VUE_APP_GLOBAL_TICKET_URL !== undefined
        },

        changeCompany: function () {
            this.allowCloseCompanyModal = true
            this.$refs.companyModal.show()
        },

        initializeUI: function () {
            this.initRoles()
            if(this.$route.query.feedbackGuid && this.ticketEnabled) {
                this.feedbackGuid = this.$route.query.feedbackGuid
                this.activeView = 'ticketSystem'
            } else if (this.activeView !== 'map') {
                this.activeView = 'map'
            } else {
                this.activeView = null
                this.$nextTick(function () {
                    this.activeView = 'map'
                })
            }
            this.centerToCompany()
            this.startInactivityTimer()
        },

        startInactivityTimer: function () {
            document.body.addEventListener('click', this.restartInactivityTimer, true);
            this.restartInactivityTimer();
        },

        restartInactivityTimer: function () {
            if (this.loggedIn) {
                this.inactivityStartTime = new Date();
                if (this.inactivityTimer) {
                    clearTimeout(this.inactivityTimer)
                }
                if (this.TIMEOUT && this.TIMEOUT > 0) {
                    this.inactivityTimer = setTimeout(function (that) {
                        // TODO - Should we warn the user before logging out/ refresh page with a dialog?
                        that.timedLogout()
                    }, this.TIMEOUT, this)
                }
            }
        },

        timedLogout: function () {
            if (this.loggedIn) {
                this.loginMessage = this.$t('common.timeout_logout')
                this.logout()
            }
        },

        centerToCompany: function () {
            if (this.user.company && this.user.company.location && !this.mapCenter) {
                this.mapCenter = {
                    lat: this.user.company.location.y,
                    lng: this.user.company.location.x
                }
            }
        },

        saveMapCenter: function(newMapCenter, zoom) {
            this.mapCenter = newMapCenter
            this.mapZoom = zoom
        },

        showTicketSystem: function() {
            this.feedbackGuid = null
            this.setActiveView('ticketSystem')
        },

        loginFailed: function () {
            this.logout()
            this.showAlert(this.$t('login.init_fail'))
        },

        resetRoles: function () {
            this.isAdmin = false
            this.isObserver = false
            this.isSuperUser = false
            this.isWorker = false
            this.isMapViewer = false
            this.hasAccess = false
        },

        initRoles: function () {
            if (this.user && this.user.roles) {
                if (this.hasAdmin(this.user.roles)) {
                    this.isAdmin = true
                    this.activeView = 'map'
                }
                if (this.hasObserver(this.user.roles)) {
                    this.isObserver = true
                    if (!this.activeView) {
                        this.activeView = 'map'
                    }
                }
                if (this.hasCompanySuperUser(this.user.roles)) {
                    this.isSuperUser = true
                    if (!this.activeView) {
                        this.activeView = 'users'
                    }
                }
                if (this.hasWorker(this.user.roles)) {
                    this.isWorker = true
                    if (!this.activeView) {
                        this.activeView = 'map'
                    }
                }
                if (this.hasMapViewer(this.user.roles)) {
                    this.isMapViewer = true
                    if (!this.activeView) {
                        this.activeView = 'map'
                    }
                }
                this.isInitialized = true
            }
            registerForNotifications(this, this.onMessage).catch(() => {
            });
        },

        toggleSidebar: function () {
            if (this.isAdmin || this.isObserver || this.isWorker) {
                this.sidebarShrink = !this.sidebarShrink
            }
        },

        showAlert: function (message, variant) {
            this.alertVariant = variant
            this.alertDismissCountDown = this.alertDismissSecs
            this.alertMessage = message
        },

        alertCountDownChanged(dismissCountDown) {
            this.alertDismissCountDown = dismissCountDown
        },

        setActiveView: function (viewName, item = null) {
            if (viewName !== this.activeView) {
                this.activeView = viewName
                this.shortcutItem = item
            }
        },

        setInspectionParams: function (viewName, inspectionType, inspectionItem) {
            this.inspectionItem = {
                ...inspectionItem,
                inspectionType: inspectionType
            }
            this.setActiveView(viewName)
        },

        isView: function (viewName) {
            return viewName === this.activeView
        },

        isGroupOpen: function (menu) {
            var result = false
            for (var value of menu) {
                if (value === this.activeView) {
                    result = true
                    break
                }
            }
            return result
        },

        closeLoginForm: function () {
            this.loggedIn = true
        },

        async logout() {
            this.loading = true
            this.logoutEvent = true
            await unregisterFromNotifications(this)
            this.$store.commit('storeToken', null)
            this.$store.commit('storeMapSettings', {}) // Default map settings
            this.loggedIn = false
            this.loading = false
            this.resetUserData(
            this.$nextTick(() =>  {
                location.reload()
            }))
        },

        resetUserData: function () {
            this.activeView = null
            this.user = null
            this.userCompanies = []
            this.isInitialized = false
            this.resetRoles()
        }

    }
}
</script>

<style lang="scss">
a:link, a:visited {
    color: #BCBCBC;
}

a:hover {
    color: #f0f0f0;
}

a:active {
    color: #faa633;
}

.logout {
    margin-left: 1em;
}

.dropdown .dropdown-menu .dropdown-item, .dropdown-item:focus {
    outline: none;
    color: #404041;
}

</style>
